<template>
  <div>
    <section class="goal">
      <h2>フォーム入力</h2>
      <div class="wrap">
        <ul>
        <li>
          <p>名前</p>
          <input :value="name" @change="(e) => { name = e.target.value }" />
          <p class="error_text">{{nameError}}</p>
        </li>
        <li>
          <p>メール</p>
          <input :value="email" @change="(e) => { email = e.target.value }" />
          <p class="error_text">{{emailError}}</p>
        </li>
        <li>
          <p>性別</p>
          <input type="radio" value="男性" :checked="gender === '男性'" @change="(e) => { gender = e.target.value }" />男性
          <input type="radio" value="女性" :checked="gender === '女性'" @change="(e) => { gender = e.target.value }" />女性
          <p class="error_text">{{genderError}}</p>
        </li>
        <li>
          <p>転職回数</p>
          <select :value="num" @change="(e) => { num = e.target.value }">
            <option :value="0">0回</option>
            <option :value="1">1回</option>
            <option :value="2">2回</option>
          </select>
        </li>
      </ul>
      <button @click="onClickSubmit">送信</button>
      </div>
    </section>
    <hr class="border" />
    <p class="border_txt">これ以下は模写不要</p>
    <section class="question">
      <h2>問題</h2>
      <div class="wrap">
        <p class="question_txt">以上のアプリケーションを作成しなさい</p>
      </div>
    </section>
    <section class="answer">
      <h2>回答</h2>
      <div v-if="showAnswer">
        <button class="answer_btn" @click="showAnswer = false">
          回答を非表示
        </button>
        <div class="wrap">
          <pre>
            <code v-pre>
&lt;template&gt;
  &lt;div&gt;
    &lt;ul&gt;
        &lt;li&gt;
          &lt;p&gt;名前&lt;/p&gt;
          &lt;input :value="name" @change="(e) =&gt; { name = e.target.value }" /&gt;
          &lt;p class="error_text"&gt;{{nameError}}&lt;/p&gt;
        &lt;/li&gt;
        &lt;li&gt;
          &lt;p&gt;メール&lt;/p&gt;
          &lt;input :value="email" @change="(e) =&gt; { email = e.target.value }" /&gt;
          &lt;p class="error_text"&gt;{{emailError}}&lt;/p&gt;
        &lt;/li&gt;
        &lt;li&gt;
          &lt;p&gt;性別&lt;/p&gt;
          &lt;input type="radio" value="男性" :checked="gender === '男性'" @change="(e) =&gt; { gender = e.target.value }" /&gt;男性
          &lt;input type="radio" value="女性" :checked="gender === '女性'" @change="(e) =&gt; { gender = e.target.value }" /&gt;女性
          &lt;p class="error_text"&gt;{{genderError}}&lt;/p&gt;
        &lt;/li&gt;
        &lt;li&gt;
          &lt;p&gt;転職回数&lt;/p&gt;
          &lt;select :value="num" @change="(e) =&gt; { num = e.target.value }"&gt;
            &lt;option :value="0"&gt;0回&lt;/option&gt;
            &lt;option :value="1"&gt;1回&lt;/option&gt;
            &lt;option :value="2"&gt;2回&lt;/option&gt;
          &lt;/select&gt;
        &lt;/li&gt;
      &lt;/ul&gt;
      &lt;button @click="onClickSubmit"&gt;送信&lt;/button&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
export default {
  data() {
    return {
      name: "",
      nameError: "",
      email: "",
      emailError: "",
      gender: "",
      genderError: "",
      num: 0,
    };
  },
  methods: {
    onClickSubmit() {
      let isError = false;
      this.nameError = "";
      this.emailError = "";
      this.genderError = "";
      if (this.name === "") {
        this.nameError = "名前は必須です";
        isError = true;
      }
      if (!/.+@.+.+/.test(this.email)) {
        this.emailError = "正しい形式で入力してください";
        isError = true;
      }
      if (this.gender === "") {
        this.genderError = "性別を選択してください";
        isError = true;
      }
      if (isError) return;
      alert(`
    送信に成功しました！！
    ーーーーーーーーーーー
    名前：${this.name}
    メール：${this.email}
    性別：${this.gender}
    転職回数：${this.num}
    `);
    },
  },
};
&lt;/script&gt;
&lt;style lang="scss" scoped&gt;
.error_text {
  color: red;
}
&lt;/style&gt;
            </code>
          </pre>
        </div>
      </div>
      <button v-else class="answer_btn" @click="showAnswer = true">
        回答を表示
      </button>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAnswer: false,
      name: "",
      nameError: "",
      email: "",
      emailError: "",
      gender: "",
      genderError: "",
      num: 0,
    };
  },
  methods: {
    onClickSubmit() {
      let isError = false;
      this.nameError = "";
      this.emailError = "";
      this.genderError = "";
      if (this.name === "") {
        this.nameError = "名前は必須です";
        isError = true;
      }
      if (!/.+@.+.+/.test(this.email)) {
        this.emailError = "正しい形式で入力してください";
        isError = true;
      }
      if (this.gender === "") {
        this.genderError = "性別を選択してください";
        isError = true;
      }
      if (isError) return;
      alert(`
    送信に成功しました！！
    ーーーーーーーーーーー
    名前：${this.name}
    メール：${this.email}
    性別：${this.gender}
    転職回数：${this.num}
    `);
    },
  },
};
</script>
<style lang="scss" scoped>
.error_text {
  color: red;
}
</style>